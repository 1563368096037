import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { Badge, Grid } from '@material-ui/core'

export default function Status({ children, color, pulse }) {
  const StyledBadge = withStyles(theme => ({
    badge: {
      backgroundColor: color,
      color: color,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
      transform: children ? null : 'scale(1) translate(50%, 50%)',
    },
    '@keyframes ripple': {
      '0%': {
        transform: pulse ? 'scale(.8)' : 'initial',
        opacity: 1,
      },
      '100%': {
        transform: pulse ? 'scale(2.4)' : 'initial',
        opacity: 0,
      },
    },
  }))(Badge)

  return (
    <Grid
      style={
        children
          ? null
          : {
              width: 20,
            }
      }
    >
      <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: children ? 'right' : 'left',
        }}
        variant="dot"
      >
        {children}
      </StyledBadge>
    </Grid>
  )
}
